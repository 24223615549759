'use client';
import { useEffect, useState } from 'react';

import { Text, textVariants } from '@pbx/shared/ui/common/text';
import { Button } from '@pbx/shared/ui/common/button';
import { Icon } from '@pbx/shared/ui/common/icon';
import { Slider } from '@pbx/shared/ui/common/slider';
import { RichText } from '@pbx/shared/ui/common/rich-text';
import { Link } from '@pbx/shared/ui/common/link';
import { replaceRichTextTags } from '@pbx/shared/contentful/helpers';
import { useAnalytics } from '@pbx/shared/analytics';

import { TSavingsCalculatorProps } from './savings-calculator.types';

export function SavingsCalculator({
  title = 'You could save',
  button,
  text,
}: TSavingsCalculatorProps) {
  const analytics = useAnalytics();
  const [propertyPriceSliderValue, setPropertyPriceSliderValue] =
    useState<number>(300000);

  const [estateAgentFeeSliderValue, setEstateAgentFeeSliderValue] =
    useState<number>(1.3);

  const [step, setStep] = useState<number>(5000);

  const [showEstateAgentFeeSlider, setShowEstateAgentFeeSlider] =
    useState<boolean>(false);

  const estateAgentFee = getEstateAgentFee(estateAgentFeeSliderValue);

  const savings = estateAgentFee;

  function handleEstateAgentFeeSliderChange(value: number[]) {
    const sliderValue = value[0];
    setEstateAgentFeeSliderValue(sliderValue);
  }

  function handlePropertyPriceSliderChange(value: number[]) {
    const sliderValue = value[0];
    setPropertyPriceSliderValue(sliderValue);
  }

  function trackValueCommit(value: number[]): void {
    analytics.trackGAEvent('slider', {
      parameter: 'property selling price',
      value: value[0],
    });
  }

  function getEstateAgentFee(fee: number): number {
    return (propertyPriceSliderValue * fee) / 100;
  }

  function incrementHousePriceSliderStep(value: number) {
    if (value >= 100000 && value <= 300000) {
      setStep(5000);
    } else if (value > 300000 && value < 700000) {
      setStep(10000);
    } else if (value >= 700000 && value <= 1200000) {
      setStep(25000);
    } else if (value > 1200000 && value <= 2000000) {
      setStep(50000);
    }
  }

  function handleEstateAgentFeeSliderToggleClick() {
    setShowEstateAgentFeeSlider(!showEstateAgentFeeSlider);
  }

  function formatNumber(number: number): string {
    return number?.toLocaleString('en', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  const placeholderTagReplacements = {
    '[savings]': formatNumber(savings),
    '[agentFee]': estateAgentFeeSliderValue,
  };

  const updatedText = replaceRichTextTags(text, placeholderTagReplacements);

  useEffect(() => {
    incrementHousePriceSliderStep(propertyPriceSliderValue);
  }, [propertyPriceSliderValue]);

  return (
    <div className="bg-brand-background-primary sm:rounded-large md:rounded-large flex flex-col items-center sm:p-4 md:p-6 lg:p-8">
      <div className="flex max-w-[556px] flex-col gap-4">
        <div>
          <Text
            variant="heading"
            size="small"
            as="h2"
            className="text-brand-foreground-primary"
            data-testid="savings-calculator-title"
          >
            {title}
          </Text>

          <Text
            variant="display"
            size="large"
            className="text-brand-foreground-highlight"
          >
            £{formatNumber(savings)}
          </Text>
        </div>

        <div>
          <div className="text-brand-foreground-secondary flex justify-between sm:flex-col">
            <Text variant="ui">Property selling price</Text>
            <Text variant="ui">£{formatNumber(propertyPriceSliderValue)}</Text>
          </div>
          <Slider
            variant="brand"
            defaultValue={[propertyPriceSliderValue]}
            min={100000}
            max={2000000}
            step={step}
            onValueChange={handlePropertyPriceSliderChange}
            onValueCommit={trackValueCommit}
            id="property-selling-price"
          />
        </div>

        {showEstateAgentFeeSlider ? (
          <div>
            <div className="text-brand-foreground-secondary flex justify-between sm:flex-col">
              <Text variant="ui">Estate agent fee</Text>
              <Text variant="ui">{estateAgentFeeSliderValue}%</Text>{' '}
            </div>
            <Slider
              variant="brand"
              defaultValue={[estateAgentFeeSliderValue]}
              min={1}
              max={3}
              step={0.1}
              onValueChange={handleEstateAgentFeeSliderChange}
              id="estate-agent-fee"
            />
          </div>
        ) : null}

        <Button variant="primary" inverted={true} className="w-fit">
          <Link href={button.link.href || ''}>{button.label}</Link>
        </Button>

        {!showEstateAgentFeeSlider ? (
          <Button
            variant="tertiary"
            icon={<Icon name="percentage" width={24} height={24} />}
            iconPosition="left"
            inverted={true}
            className="w-fit"
            onClick={handleEstateAgentFeeSliderToggleClick}
          >
            Change comparison fee
          </Button>
        ) : null}

        <RichText
          text={updatedText}
          disableMargins
          className="text-brand-foreground-secondary"
          customClasses={{
            p: textVariants({
              variant: 'paragraph',
              size: 'small-tight',
            }),
          }}
        />
      </div>
    </div>
  );
}
