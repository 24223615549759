import { TFetchConfig } from '../api-core.types';

export async function requestInterceptor(
  config: TFetchConfig
): Promise<TFetchConfig> {
  console.log('Request Interceptor');

  if (config?.body) {
    config.body = JSON.stringify(config.body);
  }

  // Access and log custom properties if needed
  if (config?.platform) {
    console.info(`Platform: ${config.platform}`);
  }

  return config;
}

// Request Interceptor Error: Handle errors that occur during the request configuration phase
export function requestInterceptorError(error: unknown): Promise<Error> {
  console.log('Request Interceptor Error');
  return Promise.reject(error);
}

// Response Interceptor: Modify or handle the response before it’s returned
export async function responseInterceptor(
  response: Response
): Promise<Response> {
  console.log('Response Interceptor');

  // Custom logic for handling the response can be added here

  return response;
}

// Response Interceptor Error: Handle errors that occur during the response phase
export function responseInterceptorError(error: Error): Promise<Error> {
  console.log('Response Interceptor Error');
  return Promise.reject(error);
}
